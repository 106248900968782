
import TheMapMarker from "@/components/SVG/TheMapMarker.vue";
import TheEmail from "@/components/SVG/TheEmail.vue";
import ThePhone from "@/components/SVG/ThePhone.vue";
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, email } from "@vuelidate/validators";

export default defineComponent({
  components: { TheMapMarker, TheEmail, ThePhone },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isMessageSent = ref(false);
    const messageSentFeedback = ref("");
    const state = reactive({
      type: "contact",
      firstname: "",
      lastname: "",
      message: "",
      email: "",
    });

    const rules = {
      firstname: { required: helpers.withMessage(t("required"), required) },
      lastname: { required: helpers.withMessage(t("required"), required) },
      email: {
        required: helpers.withMessage(t("required"), required),
        email: helpers.withMessage(t("email"), email),
      },
      message: {
        required: helpers.withMessage(t("required"), required),
        minLength: helpers.withMessage(t("minL"), minLength(10)),
      },
    };

    const v$ = useVuelidate(rules, state, { $autoDirty: true });

    const submit = async () => {
      const isValid = await v$.value.$validate();
      if (!isValid) return;
      messageSentFeedback.value = await store.dispatch("sendMessage", state);
      isMessageSent.value = true;
      state.firstname = "";
      state.lastname = "";
      state.message = "";
      state.email = "";
      v$.value.$reset();
    };

    return { t, submit, v$, state, isMessageSent, messageSentFeedback };
  },
});
